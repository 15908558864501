import React, { Fragment, useContext } from "react"
import { Formik, Form } from "formik"
import { navigate } from "gatsby"

import Layout from "layout/Layout"
import Section from "elements/Section"
import Message from "elements/Message"
import Container from "layout/Container"
import ActionButtons from "elements/ActionButtons"
import { generateFormField } from "elements/Form/services/form"

import { AppContext } from "../../../../context/AppContext"
import { useMentalHealthFormFields } from "../hooks/useMentalHealthFormFields"

import { isObjectEmpty } from "services/general"

const BookingAssessment = ({ pageContext }) => {
  const { state, dispatch } = useContext(AppContext)
  const { selfRequest } = state

  let { sectionFormFields, validationSchema } = useMentalHealthFormFields({
    formFields: pageContext.formFields,
  })

  const handleSubmit = async (values) => {
    await dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        selfRequest: {
          ...selfRequest,
          notes: values.notes || "",
          cssrs: values,
        },
      },
    })
    navigate(pageContext.nextPath)
  }

  return (
    <Layout
      title={pageContext?.module?.title}
      subtitle={pageContext?.module?.subtitle}
      seoTitle={pageContext?.module?.seoTitle}
      pageContext={pageContext}
      isPrivate
    >
      <Container isCentered>
        <Formik
          initialValues={{ ...state?.selfRequest?.cssrs }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, errors }) => (
            <Form>
              {sectionFormFields.map((section) => (
                <Section
                  title={section?.section}
                  subtitle={section?.subtitle || ""}
                >
                  {section?.message && (
                    <Message color="light">{section?.message}</Message>
                  )}
                  {section?.fields.map((field) => {
                    if (!field?.referenceAnswer) {
                      return (
                        <Fragment>
                          {generateFormField({
                            formFields: section?.fields,
                            formField: field,
                            values,
                            setFieldValue,
                          })}
                          {!!field?.addDividerAfterField && (
                            <hr className="has-background-light" />
                          )}
                        </Fragment>
                      )
                    }
                    return null
                  })}
                </Section>
              ))}

              {!isObjectEmpty(errors) && (
                <Message>
                  You may have missed some required fields. Please scan through
                  the form and check if your information is complete.
                </Message>
              )}

              <ActionButtons
                back={{ label: "Back", link: pageContext.backPath }}
                submit={{ label: "Next" }}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default BookingAssessment
